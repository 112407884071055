import React, { useState, useCallback, useEffect } from "react";
import { toast } from 'react-toastify';
import Table from "../components/Table";
import Spinner from "../components/circularProgress"
import Switch from "../components/switch"
import CrossRefOrderRow from "../components/manageCrossRefOrders/row";
import OrderService from "../services/order.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder } from "../utils/appConstants";

function ManageCrossRefOrders({ user, loggedInUser, actAsDomainIdMemoised }) {
  const [exportLoading, setExportLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('all');
  const [filterText, setFilterText] = useState("");
  const [filterTextForApi, setFilterTextForApi] = useState("");

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.desc, defaultOrderBy: 'tandem_id' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: orders, total, loading: ordersLoading } = useFetch({
    service: OrderService,
    func: 'getCrossRefOrders',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      domainId: actAsDomainIdMemoised || (user.attributes['custom:role'] == "superadmin" && 'all'),
      actAsDomainId: actAsDomainIdMemoised,
      filterKey,
      searchText: filterTextForApi
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage, actAsDomainIdMemoised, filterKey, filterTextForApi],
  });

  const headCells = [{
    id: 'expand-collapse',
    accessor: 'expand-collapse',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "",
    width: "5%",
  }, {
    id: 'tandem_id',
    accessor: 'tandem_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Tandem Id",
    width: "20%",
  }, {
    id: 'metered_load.order_number',
    accessor: 'metered_load.order_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Order Number",
    width: "15%",
  }, {
    id: 'field_values',
    accessor: 'translated_data',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Causes",
    width: "40%",
  }, {
    id: 'domain_id',
    accessor: 'carrier_domain_id.domain_name',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Domain",
    width: "10%",
  }, {
    id: 'created_date',
    accessor: 'created_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Created Date",
    width: "10%",
  }]

  const exportCrossRefOrders = useCallback(() => {
    setExportLoading(true)
    OrderService.exportCrossRefOrders({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      domainId: actAsDomainIdMemoised,
      actAsDomainIdMemoised,
      filterKey,
      searchText: filterTextForApi
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `CrossRefErroredOrders.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, actAsDomainIdMemoised, filterKey,filterTextForApi])

  const onSwitchChange = (checked) => {
    if(checked) {
      setFilterKey('all')
    } else {
      setFilterKey('active')
    }
  }

  const handleFilterOrders = useCallback(() => {
    setFilterTextForApi(filterText)
    setPage(1)
  }, [filterText])

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  }

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  return (
    <div className="summary-root">
      <div className="table-layout orders-table">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Cross Ref Orders</h3>
          {
            <div className="filter-box">
              <div className="search-box">
                <input value={filterText} onChange={handleFilterTextChange} placeholder="Search by keywords..." />
                <button className={`btn filter-order-btn`} disabled={ordersLoading} onClick={handleFilterOrders}>
                  {
                    ordersLoading ? <Spinner size={22} color="white" /> : `Search`
                  }
                </button>
              </div>
              <div className="switch-layout">
                <label className="switch-label complementory">Active</label>
                <Switch color="primary" size="medium" onChange={(event) => onSwitchChange(event.target.checked)} checked={filterKey === 'all'} />
                <label className="switch-label blue-1">All</label>
              </div>
              <button className={`btn`} onClick={exportCrossRefOrders}>
                {
                  exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                }
              </button>
            </div>
          }
        </div>
      </div>
      <div className="table-layout orders-table">
        <div className="orders-table-main">
          <Table
            data={orders}
            loading={ordersLoading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={CrossRefOrderRow}
            rowProps={{
              user
            }}
            noDataFoundMessage={`No Orders Found With Cross Ref Errors`}
          />
        </div>
      </div>
    </div>
  )
}

export default ManageCrossRefOrders;