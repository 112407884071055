import http from '../httpConfig';

const getDomains = ({token, order, orderBy, page, perPage, searchText, id, isList, isProxyDoamins,isListCarrierAlias}) => {
  let url = '/domain';
  if(order && orderBy && page && perPage) {
    url = `${url}?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}&searchText=${searchText || ''}&id=${id || ""}&isList=${!!isList}&isProxyDoamins=${!!isProxyDoamins}&isListCarrierAlias=${!!isListCarrierAlias}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addDomain = (token, payload) => {
  return http.post('/domain', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const editDomain = (token, payload) => {
  return http.put('/domain', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const validateDomain = (email) => {
  return http.get(`/domain/verify?domain_name=${email}`);
};

const deleteDomain = (token, domainId) => {
  return http.delete(`/domain/${domainId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const DomainService = {
  getDomains,
  addDomain,
  editDomain,
  deleteDomain,
  validateDomain
};

export default DomainService;