import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { dateFormat } from "../../utils/methods";
import _ from "lodash";

const Row = ({ item, headCells, handleEdit, handleDelete }) => {
  const ExpandableTableRow = ({ children, itemData }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    let domains = _.get(itemData, 'domains')
    let domains_proxy = _.get(itemData, 'proxy_domain_list')

    return (
      <>
        <TableRow className="table-body-row">
          <TableCell>
            <IconButton classes={{
              root: 'icon-btn-root'
            }} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow className="table-body-row-expanded">
            <TableCell colSpan={headCells.length}>
              <label className='table-body-row-expanded-label'>Carriers associated with proxy</label>
              {
                domains_proxy && domains_proxy.length 
                ? 
                  <ul className='table-body-row-expanded-list'>
                    {
                      domains_proxy.map(domain => {
                        return (
                          <li className='table-body-row-expanded-list-item'>
                            <label className='table-body-row-expanded-list-item-label'>Carrier Alias / Domain Name: </label>
                            <span>({domain?.domain_id?.carrier_alias || '-'} / {domain?.domain_id?.domain_name || '-'})</span>
                          </li>
                        )
                      })
                    }
                  </ul>
                : <p>No domains associated with the proxy</p>
              }
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <ExpandableTableRow
      key={item._id}
      itemData={item}
    >
    {/* <TableRow className="table-body-row"> */}
      {
        headCells.filter(item => item.id !== 'expand-collapse').map((headCell) => {
          if (headCell.id === 'created_date') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(new Date(value).toISOString()) : ''} />);
          }

          if(headCell.id === "domains_count") {
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={item?.proxy_domain_list?.length || 0} />);
          }

          if(headCell.id === "parent_domain") {
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={item?.domain_id?.domain_name || ''} />);
          }

          if (headCell.id === 'action') {
            if (item.isDeleted) {
              return (
                <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                  <label>Archived</label>
                </TableCell>
              )
            } else {
              return (
                <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                  <EditIcon className='table-edit-icon' onClick={() => handleEdit(item)} />
                  <DeleteIcon className='table-delete-icon' onClick={() => handleDelete(item)} />
                </TableCell>
              )
            }
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    {/* </TableRow> */}
    </ExpandableTableRow>
  );
};

export default Row;
