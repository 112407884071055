import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Routes, Route,Navigate
} from 'react-router-dom';
import Layout from './components/layout';
import UserService from './services/user.service';
import Dashboard from "./pages/dashboard";
import Swagger from "./pages/swagger";
import ManageOrders from "./pages/manageOrders";
import ManageCrossRefOrders from "./pages/manageCrossRefOrders";
import ManageDomains from "./pages/manageDomains";
import ManageProxies from "./pages/manageProxies";
import ManageDrivers from "./pages/manageDrivers";
import ManageVehicles from "./pages/manageVehicles";
import ManageUsers from "./pages/manageUsers";
import ManageShipperIds from "./pages/manageShipperIds";
import ManageCustomerIds from "./pages/manageCustomerIds";
import ManagePublicCustomerIdsMapping from "./pages/managePublicCustomerIdsMapping";
import ManageCarrierIdsToTcn from "./pages/manageCarrierIdsToTcn";
import ManageProducts from "./pages/manageProducts";
import Summary from "./pages/summary";
import { withAuthenticator } from '@aws-amplify/ui-react';
import ManageLoadingNumber from './pages/manageLoadingNumber';

function AuthenticatorRoutes({ signOut, user }) {
  const [loggedInUser, setLoggedInUser] = useState({})
  const [actAsDomainId, setActAsDomainId] = useState("");

  const handleActAsDomainIdChange = (event) => {
    setActAsDomainId(event.target.value)
  }

  const getUsers = useCallback((token) => {
    UserService.getLoggedInUser(token)
      .then(res => {
        if (res.data.data) {
          const userObj = res.data.data;
          setActAsDomainId(userObj.domain_id)
          setLoggedInUser(userObj)
        }
      })
      .catch(() => {
      })

  }, [user?.signInUserSession?.accessToken?.jwtToken])

  useEffect(() => {
    getUsers(user?.signInUserSession?.accessToken?.jwtToken)
  }, [user?.signInUserSession?.accessToken?.jwtToken])

  const actAsDomainIdMemoised = useMemo(() => {
    if (loggedInUser?.role_id?.role_name === 'superadmin') {
      if (loggedInUser?.domain_id === actAsDomainId) {
        return ''
      } else {
        return actAsDomainId
      }
    } else {
      return ''
    }
  }, [JSON.stringify(loggedInUser), actAsDomainId])

  return (
    <Routes>
      <Route path={'/dashboard'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin', 'fulluser', 'limiteduser']}>
          <Dashboard user={user} loggedInUser={loggedInUser} />
        </Layout>
      } />
      <Route path={'/swagger'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin', 'fulluser', 'limiteduser']}>
          <Swagger user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/orders'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin']}>
          <ManageOrders user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/domains'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin']}>
          <ManageDomains user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/proxies'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin']}>
          <ManageProxies user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/drivers'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageDrivers user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/vehicles'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageVehicles user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/users'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageUsers user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/shipperIds'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageShipperIds user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/products'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin', 'fulluser', 'limiteduser']}>
          <ManageProducts user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/customerIds'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageCustomerIds user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      {/* <Route path={'/public-customer-ids-mapping'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManagePublicCustomerIdsMapping user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } /> */}

      <Route path={'/carrier-ids-to-tcn'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageCarrierIdsToTcn user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/loading-numbers'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageLoadingNumber user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/orders-summary'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['admin']}>
          <Summary user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/cross-ref-orders'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={['superadmin', 'admin']}>
          <ManageCrossRefOrders user={user} actAsDomainIdMemoised={actAsDomainIdMemoised} loggedInUser={loggedInUser} />
        </Layout>
      } />

      <Route path={'/no-access'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={[]}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}>
            <h1>You don't have permissions to access this feature.</h1>
            <h2>Please contact administration for more information.</h2>
          </div>
        </Layout>
      } />

      <Route path={'/no-domain-access'} element={
        <Layout user={user} signOut={signOut} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} hasAccess={[]}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}>
            <h1>Domain verification failed, please contact administrator.</h1>
          </div>
        </Layout>
      } />

      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

export default withAuthenticator(AuthenticatorRoutes);
